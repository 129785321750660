import React from "react";

const OptionAccordion = ({icon, title, description, isActive=false, selectAction, children}) => {
    return (
        <section className={`accordion ${isActive ? "open" : "closed"}`}>
            <div className="wrapper">
                <section className="optionHeader">
                    <div className="first">
                        <i className={icon} />
                        <h3> {title} </h3>    
                    </div>
                    <div className="second">
                        {description}
                    </div>
                    <div className="third">
                        {selectAction}
                    </div>
                </section>
                <section className={`innerContent ${isActive ? "" : "hidden"}`}>
                    { children }
                </section>
            </div>
        </section>
    )
}

export default OptionAccordion
