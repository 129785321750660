import React from "react";

const NotFound = () => {
	return (
		<section className="screen not-found">
			<h2>HTTP 404 – Seite nicht gefunden.</h2>
		</section>
	);
};

export default NotFound;