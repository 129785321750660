import React    from "react";
import useStore from "../../Store";

const ReceiverAddress = ({title}) => {
	const parcel      = useStore(store => store.parcel)
	const showDetails = useStore(store => store.showParcelDetails)

	return (
		<>
			<address>
				<b className="heading">{title}</b>
				{showDetails ?
					<>
						<p>
							{parcel?.empfangsperson}<br />
							{parcel?.empfang_strasse}<br />
							{parcel?.empfang_plz} {parcel?.empfang_ort}
						</p>
						<p>
							{parcel?.telefonnummer}
						</p>
					</> : 
					<>
						<p>
							{parcel?.empfangsperson}
						</p>
					</>
				}
			</address>
		</>
	);
};

export default ReceiverAddress;