import React, {useMemo} from "react";

import PackagingTypes from "../../models/PackagingTypes";

const getPackagingTypeName = shorthand => {
	return PackagingTypes[shorthand]?.description;
};

const ParcelOverview = ({parcel}) => {
	const packagingData = useMemo(() => {
		if (!parcel)
			return null;
		const parcelContent = {};

		for (let i = 1; i < 4; i++) {
			const name   = getPackagingTypeName(parcel[`verpackung${i}`]?.trim());
			const amount = parcel[`anzahl${i}`];
			if (name && name !== "" && parseInt(amount) > 0) {
				if (!parcelContent.hasOwnProperty(name))
					parcelContent[name] = 0;
				parcelContent[name] += parseInt(amount);
			}
		}

		return parcelContent;
	}, [parcel]);

	const markers = useMemo(() => {
		if (!parcel)
			return null;
		const markers = [];

		for (let i = 1; i < 4; i++) {
			const marker = parcel[`markierung${i}`];
			if (marker !== "")
				markers.push(marker);
		}
	}, [parcel]);

	return (
		<>
			<table>
				<tbody>
					<tr>
						<td>Liefernummer</td>
						<td>{parcel?.sssnr}</td>
					</tr>
					{markers && markers.length > 0 ?
						<tr>
							<td>Markierung</td>
							<td>
								{markers.map((marker, index) => <span key={index}>{marker}<br /></span>)}
							</td>
						</tr>
					: null}
					{Object.keys(packagingData).map((item, index) => {
						return (
							<tr key={index}>
								<td>{item}</td>
								<td>{packagingData[item]}</td>
							</tr>
						);
					})}
				</tbody>
			</table>
		</>
	);
};

export default ParcelOverview;