import React, {useState, useEffect} from "react";

import useText from "../../components/hooks/useText";

import CheckContactData      from "../../components/CheckContactData";
import DeclineOption         from "../../components/DeclineOption";
import DeliveryDateOption    from "../../components/DeliveryDateOption";
import DropOffLocationOption from "../../components/DropOffLocationOption";
import OptionAccordion       from "../../components/OptionAccordion";
import SelfPickupOption      from "../../components/SelfPickupOption";
import OptionConfirmationBox from "../../components/OptionConfirmationBox";
import ConfirmationMessage   from "../../components/ConfirmationMessage";
import CompletedParcelInfo   from "./CompletedParcelInfo";
import useStore              from "../../Store";

import Finisher from "../../components/Finisher";

const ModifyParcel = ({parcel}) => {
    const [activeOption, setActiveOption]                   = useState(null);
    const deliveryDates                                     = useStore(store => store.deliveryDates)
    const [choosenOption, setChoosenOption]                 = useStore(store => [store.choosenDeliveryOption, store.setChoosenDeliveryOption]);
    const DELIVERY_OPTIONS                                  = useStore.DELIVERY_OPTIONS;
    const setSelectedDeliveryDate                           = useStore(store => store.setSelectedDeliveryDate)
    const setShowAGB                                        = useStore(store => store.setShowAGB);
    const acceptedAGB                                       = useStore(store => store.acceptedAGB);
    const setShowAcceptButton                               = useStore(store => store.setShowAcceptButton);
    const deliveryDateFound                                 = useStore(store => store.deliveryDateFound);
    const [proceedToConfirmation, setProceedToConfirmation] = useState(false);
    const processFinished                                   = useStore(store => store.processFinished);
    const readOnly                                          = useStore(store => store.readOnly);

	const textDepositInfo = useText("abstellung-info", parcel?.firma);
	const textPickupInfo  = useText("abholung-info", parcel?.firma);
	const textDelcineInfo = useText("verweigern-info", parcel?.firma);

    const [deliveryDatesLoading, setDeliveryDatesLoading] = useState(true);

    /* @todo Check if Server respondes with infinite Loop */
    useEffect(() => {
        deliveryDates.length > 0 ? setDeliveryDatesLoading(false) : setDeliveryDatesLoading(true); 
    }, [deliveryDates])

    useEffect(() => {
        if (acceptedAGB === true) {
            setProceedToConfirmation(true);
        }
    }, [acceptedAGB])
    
	return (
		<>
			{readOnly ? 
				<section className="wrapper">
					<OptionConfirmationBox parcel={parcel} />
				</section>
			: null}
            {proceedToConfirmation ? 
                <>
                    <section className="wrapper">
                        <OptionConfirmationBox parcel={parcel} />
                    </section>

                    {!processFinished ? 
                        <CheckContactData />
                        : 
                        <ConfirmationMessage parcel={parcel} />
                    }
                </>
                :
                <section className="modify-delivery">
                    <section className="wrapper">
					{!readOnly ?
						<>
                        <div className="grid seventy-thirty">
                            <h1>Bitte überprüfen Sie die Lieferdaten und bestätigen Sie die Lieferung.</h1>
                        </div>
                        <OptionAccordion 
                            title="Zustellung"
                            icon="avisicon-delivery-date"
                            description={<> 
                                {
                                    deliveryDateFound === false ? 
                                        <p>Leider ist kein Liefertermin verfügbar. Bitte wählen Sie eine andere Option.</p>
                                    : deliveryDatesLoading ? 
                                        <div className="loader"><div></div><div></div><div></div></div> 
                                        : 
                                        <>
                                        <p><b> Ihr aktueller Liefertermin:</b> <br />
                                
                                        {deliveryDates.length > 0 ? new Date(deliveryDates[0].date).toLocaleDateString("de-DE", {weekday: "long", day: "numeric", month: "2-digit", year: "numeric"}) : null}<br />
                                        {deliveryDates.length > 0 ? deliveryDates[0].text : null}</p>
                                        <button onClick={() => setActiveOption(activeOption !== DELIVERY_OPTIONS.DELIVERY ? DELIVERY_OPTIONS.DELIVERY : null)} className="white-button new-delivery-dates"> {activeOption !== DELIVERY_OPTIONS.DELIVERY ? "Andere Terminvorschläge" : "Änderung abbrechen" }</button> 
                                </>
                                }
                            </>}
                            isActive={activeOption === DELIVERY_OPTIONS.DELIVERY}
                            selectAction={<>
                                            {deliveryDatesLoading ? null :
                                                <button 
                                                    className="blue-button confirm-delivery-date" 
                                                    onClick={() => {
                                                        setSelectedDeliveryDate(deliveryDates[0]) 
                                                        setChoosenOption(DELIVERY_OPTIONS.DELIVERY)
                                                        setProceedToConfirmation(true)
                                                    }}>
                                                    Diesen Termin bestätigen
                                                    </button>
                                            }
                                        </>} 
                        >

                            <DeliveryDateOption deliveryDates={deliveryDates} parcel={parcel}
                                onClickAction={() => {
                                    setChoosenOption(DELIVERY_OPTIONS.DELIVERY)
                                    setProceedToConfirmation(true)
                                }} 
                            />
                        </OptionAccordion>
                        

						{parcel && !parcel.warennachnahme ?
							<OptionAccordion 
								title={<>Abstellgenehmigung <br /> erteilen</>}
								icon="avisicon-pin"
								description={textDepositInfo}
								isActive={activeOption === DELIVERY_OPTIONS.DROP}
								selectAction={<>
												<button onClick={() => setActiveOption(activeOption !== DELIVERY_OPTIONS.DROP ? DELIVERY_OPTIONS.DROP : null)} className="white-button confirm-delivery-date"> { activeOption !== DELIVERY_OPTIONS.DROP ? "Abstellgenemigung einrichten" : "Änderung abbrechen" }</button>
											</>} 
							>   
								<DropOffLocationOption 
									onClickAction={() => {setShowAGB(true); setShowAcceptButton(true); setChoosenOption(DELIVERY_OPTIONS.DROP)}} 
									parcel={parcel}/>
							</OptionAccordion>
						: null }

                        <OptionAccordion 
                            title="Selbstabholung"
                            icon="avisicon-self-pickup"
                            description={textPickupInfo}
                            isActive={activeOption === DELIVERY_OPTIONS.SELFPICKUP}
                            selectAction={<>
                                            <button onClick={() => setActiveOption(activeOption !== DELIVERY_OPTIONS.SELFPICKUP ? DELIVERY_OPTIONS.SELFPICKUP : null)} className="white-button confirm-delivery-date"> { activeOption !== DELIVERY_OPTIONS.SELFPICKUP ? "Selbstabholung wählen" : "Änderung abbrechen"} </button>
                                        </>} 
                        >
                            <SelfPickupOption 
                                onClickAction={() => {
                                    setChoosenOption(DELIVERY_OPTIONS.SELFPICKUP)
                                    setProceedToConfirmation(true)
                                }}
                                parcel={parcel} 
                            />
                        </OptionAccordion>


                        <OptionAccordion 
                            title="Annahme verweigern"
                            icon="avisicon-refuse-delivery"
                            description={textDelcineInfo}
                            isActive={activeOption === DELIVERY_OPTIONS.DECLINE}
                            selectAction={<>
                                            <button onClick={() => setActiveOption(activeOption !== DELIVERY_OPTIONS.DECLINE ? DELIVERY_OPTIONS.DECLINE : null)} className="white-button confirm-delivery-date"> { activeOption !== DELIVERY_OPTIONS.DECLINE ? "Annahme verweigern" : "Änderung abbrechen"}</button>
                                        </>} 
                        >
                            <DeclineOption 
                                onClickAction={() => {
                                    setChoosenOption(choosenOption !== DELIVERY_OPTIONS.DECLINE ? DELIVERY_OPTIONS.DECLINE : null)
                                    setProceedToConfirmation(true)
                                }}
                                parcel={parcel} 
                            />
                        </OptionAccordion>                    
						</>
						: <CompletedParcelInfo /> }
                    </section>
		        </section> 
            }
			<Finisher />	
		</>
	);
};

export default ModifyParcel;