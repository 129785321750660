import React, {useEffect} from "react";

import {useNavigate} from "react-router";

const MainRoute = () => {
	const redirectTo = useNavigate();

	useEffect(() => {
		redirectTo("/parcel-not-found");
	}, [redirectTo]);

	return (
		<></>
	);
};

export default MainRoute;