import React, {useState, useEffect} from "react";
import useStore          from "../Store";

import { Textbox, Checkbox } from 'react-inputs-validation';

const CheckContactData = () => {
    const setDeliveryConfirmation                 = useStore(store => store.setDeliveryConfirmation)
    const setContactPerson                        = useStore(store => store.setParcelContactPerson)
    const setShowAGB                              = useStore(store => store.setShowAGB)
    const MailContact                             = useStore(store => store.parcelMailContact)
    const SMSContact                              = useStore(store => store.parcelSMSContact)
    const [validate, setValidate]                 = useState(false);
    const [formName, setFormName]                 = useState("");
    const [formIsAgbChecked, setFormIsAgbChecked] = useState(false);

    const [errors, setErrors] = useState({
        name: true,
        agb: true
    });

    const save = () => {
        setValidate(true);
    };

    useEffect(() => {
        if (!validate)
            return;

        const localErrors = {...errors};

        const valid = Object.values(localErrors).reduce((accumulator, current) => accumulator && !current, true);

        if (valid)
            setDeliveryConfirmation(true);

        setValidate(false);
    }, [validate, errors, formName, formIsAgbChecked, setDeliveryConfirmation])

    return (
        <>
            <section className="check-contact-data-section">
                <section className="wrapper">
                    <div className="grid seventy-thirty">
                        <h1>Bitte überprüfen Sie Ihre Kontaktdaten:</h1>
                    </div>
                    <section className="check-contact-data">
                        <div className="wrapper">
                            <div className="grid three-thirds check-contact-data-header">
                                <div className="first">
                                    <i className="avisicon-user"></i>
                                    <h3>Kontaktdaten</h3>
                                </div>
                                <div className="second"><p>Bitte geben Sie Ihren Namen als Empfänger der Bestätigung an.</p></div> {/* @todo was soll das heißen? */}
                            </div>
                            <section className="check-contact-data-content grid three-thirds">
                                <div className="check-contact-data-form">

                                    <div className="name-input">
                                        <label htmlFor="Name" className="label-above-input">Name</label>
                                        <Textbox
                                            attributesInput={{
                                                type: "text",
                                                id: "Name",
                                                name: "Name"
                                            }}
                                            value={formName}
                                            validate={validate}
                                            validationCallback={val => setErrors(errors => ({...errors, name: val}))}
                                            onChange={(formName) => { setFormName(formName); setContactPerson(formName) }}
                                            onBlur={() => setErrors(err => err)}
                                            validationOption={{
                                                name: "Namen",
                                                check: true,
                                                required: true,
                                                msgOnError: "Bitte geben Sie einen Namen ein"
                                            }}
                                        />
                                    </div>
                                    <p><b>Sie haben folgende Kontaktdaten hinterlegt: <br /></b></p>
                                    <div className="notification-method-wrapper">

                                    {SMSContact ? <p><b>Mobil: </b>{SMSContact}</p> : null}
                                    {MailContact ? <p><b>E-Mail: </b>{MailContact}</p> : null}
                                    </div>
                                    <div className="confirm-agb checkbox-container">
                                    
                                        <Checkbox 
                                            attributesInput={{
                                                id: "accept-agb",
                                                name: "accept-agb",
                                            }}
                                            value={formIsAgbChecked}
                                            checked={formIsAgbChecked}
                                            validate={validate}
                                            validationCallback={val => setErrors(errors => ({...errors, agb: val}))}
                                            onChange={(formIsAgbChecked) => {
                                                setFormIsAgbChecked(formIsAgbChecked)
                                            }}
                                            validationOption={{
                                                name: "AGB",
                                                check: true,
                                                required: true,
                                                msgOnError: "Bitte akzeptieren Sie die AGB"
                                            }}
                                            classNameInputBox="checkbox"
                                            classNameWrapper="checkbox-wrapper"
                                        />
                                        <label htmlFor="accept-agb" className="custom-checkbox-label">Ich habe die <a href="/" onClick={(event) => {event.preventDefault(); setShowAGB(true)}}>AGB</a> gelesen und akzeptiert.</label>
                                    </div>
                                    <button onClick={() => save()}>Bestätigen <span className="avisicon avisicon-arrow-right"></span></button>
                                </div>
                            </section>
                        </div>
                    </section>
                </section>
            </section>
        </>
    );
};

export default CheckContactData;