import {React} from "react";

import useStore from "../Store";
import useText  from "./hooks/useText";

const ConfirmationMessage = ({parcel}) => {
    const choosenOption        = useStore(store => store.choosenDeliveryOption)
    const DELIVERY_OPTIONS     = Object.freeze(useStore.DELIVERY_OPTIONS)
    const selectedDeliveryDate = useStore(store => store.selectedDeliveryDate)
    const MailContact          = useStore(store => store.parcelMailContact)
    const SMSContact           = useStore(store => store.parcelSMSContact)

    const textDropOffTimeRange = useText("abstellung-zeitfenster-bestaet", parcel?.firma);
    const textPickupTimeRange  = useText("abholung-zeitfenster-bestaetig", parcel?.firma);

    let message = {}

    message[DELIVERY_OPTIONS.DELIVERY]   = <> Ihre Lieferung kommt am <b>{new Date(selectedDeliveryDate?.date).toLocaleDateString("de-DE", {weekday: "long", day: "numeric", month: "2-digit", year: "numeric"})}</b> an. </>
    message[DELIVERY_OPTIONS.DROP]       = <> Ihre Lieferung wird <b>{textDropOffTimeRange}</b> bei Ihnen abgestellt. </>
    message[DELIVERY_OPTIONS.SELFPICKUP] = <> Sie holen die Lieferung <b>{textPickupTimeRange}</b> an der <b>oben angegebenen Abholadresse</b> ab. </>
    message[DELIVERY_OPTIONS.DECLINE]    = <> Sie haben die <b>Annahme der Lieferung verweigert</b>. </>

    return (
        <>
            <section className="confirmation-message-section">
                <section className="wrapper">
                    <h2>
                        {message[choosenOption]}
                       
                        {MailContact && SMSContact ?
                            <>
                                Wir haben Ihnen eine Bestätigung an <b>{MailContact}</b> und an <b>{SMSContact}</b> geschickt, bitte prüfen Sie Ihren Posteingang.
                            </> : 
                            <>
                                Wir haben Ihnen eine Bestätigung an <b>{MailContact ? MailContact : SMSContact }</b> geschickt, bitte prüfen Sie Ihren Posteingang.
                            </>
                        }
                        
                    </h2>
                </section>
            </section>
        </>
    );
};

export default ConfirmationMessage;