import React from "react";
import axios from "axios";

import {BrowserRouter} from "react-router-dom";
import {Route, Routes} from "react-router";

import Header from "./Header";
import Footer from "./Footer";

import NotFound         from "../routes/NotFound";
import MainRoute        from "../routes/MainRoute";
import ParcelDetails    from "../routes/ParcelDetails/ParcelDetails";
import ParcelNotFound   from "../routes/ParcelNotFound";
import ParcelLocked     from "../routes/ParcelLocked";

import AgbModal from "./shared/AgbModal";

const WebAVIS = () => {
	axios.defaults.baseURL = process.env.REACT_APP_BACKEND_URL;
	axios.defaults.timeout = 10000;

	return (
		<>
			<Header />
			<main>
				<BrowserRouter>
					<Routes>
						<Route path="/" element={<MainRoute />} />
						<Route path="/:key" element={<ParcelDetails />} />
						<Route path="/parcel-not-found" element={<ParcelNotFound />} />
						<Route path="/parcel-locked" element={<ParcelLocked />} />
						<Route path="*" element={<NotFound />} />
					</Routes>	
				</BrowserRouter>
			</main>
			<Footer />

			<AgbModal />
		</>
	);
};

export default WebAVIS;