const APIError = Object.freeze({
	BAD_REQUEST         : 400,
	UNKNOWN_ROUTE       : 401,
	MISSING_ARGUMENT    : 402,
	NO_RESULT           : 403,
	WRONG_ZIP           : 404,
	INTERNAL_SERVER     : 500,
	DATABASE_ERROR      : 501,
	INFINITE_LOOP       : 502,
	INPUT               : 600,
	LOCKED              : 601,
	TOO_MANY_WRONG_ZIPS : 602,
	ALREADY_PRESENT     : 603
});

export default APIError;