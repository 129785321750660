import React, {useState, useEffect}    from "react";
import useStore             from "../Store";
import useText              from "./hooks/useText";

import { Textbox } from 'react-inputs-validation';

const SelfPickupOption = ({onClickAction, parcel}) => {

    const [contact, setContact]           = useStore(store => [store.selfPickupContact, store.setSelfPickupContact])
    const [validate, setValidate]         = useState(false);
    const [hasNameError, setHasNameError] = useState(true);
	const branchAddress                   = useStore(store => store.branchAddress);

    const textSelfPickupTime = useText("abholung-zeitfenster", parcel?.firma);
    /* @ToDo is this needed anymore?
	const textPickupLocationName    = useText("abholung-standort-name", parcel?.firma);
    const textPickupLocationStreet  = useText("abholung-standort-str-nr", parcel?.firma);
    const textPickupLocationZip     = useText("abholung-standort-plz-ort", parcel?.firma);
	*/

    const validateForm = (event) => {
        event.preventDefault();
        setValidate(true);
    } 

    useEffect(() => {
		if (!hasNameError) 
            onClickAction();
        
	}, [hasNameError, onClickAction]);

    return (
        <form>
        <section className='col-three'>

                <div>
                    <b>Abholtermin:</b><br />
                    {textSelfPickupTime} 
                </div>
                <div>
                    <b>Abholadresse:</b><br />
					{branchAddress?.nl_name}<br />
					{branchAddress?.straße}<br />
					{branchAddress?.plz} {branchAddress?.ort}
                    <br /><br /><br />
                    <b>Name der abholenden Person:</b><br />
                    <Textbox
                        attributesInput={{
                            type: "text",
                            name: "Name",
                            className: "pickup-person"
                        }}
                        value={contact}
                        validate={validate}
                        validationCallback={
                            res => {
                                setHasNameError(res);
                                setValidate(false);
                            }
                        }
                        onChange={(contact) => {
                            setContact(contact)
                        }}
                        validationOption={{
                            name: "Name",
                            check: true,
                            required: true,
                            msgOnError: "Bitte geben Sie einen Namen ein"
                        }}
                    />
                </div>
                <div>
                    <button className='blue-button' onClick={(event) => validateForm(event)}> Selbtstabholung bestätigen </button>
                </div>
        </section>
            </form>
    )
}

export default SelfPickupOption
