import React from "react";

const Modal = ({show, setShow, children, className = ""}) => {
	if (!show)
		return <></>;

	return (
		<section className={`modal ${className}`}>
			<main className="content">
				<button className="outline thin plaintext pull-right" onClick={() => setShow(false)}><span className="avisicon avisicon-abort"></span> schließen</button>
				{children}
			</main>
		</section>
	);
};

export default Modal;