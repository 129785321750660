import React from "react";

import ReactMarkdown from "react-markdown";
import remarkGfm     from "remark-gfm";

import Modal    from "./Modal";
import useStore from "../../Store";
import useText  from "../hooks/useText";



const AgbModal = () => {
    const [showAGB, setShowAGB]                   = useStore(store => [store.showAGB, store.setShowAGB]);
    const setAcceptedAGB                          = useStore(store => store.setAcceptedAGB);
    const [showAcceptButton, setShowAcceptButton] = useStore(store => [store.showAcceptButton, store.setShowAcceptButton]);
    const agbText                                 = useText("agb");
    
    
    return (
        <>
            <Modal 
                        show={showAGB}
                        setShow={setShowAGB}
                        children={
                            <>
                                <div className="agb-content">
									<ReactMarkdown remarkPlugins={[remarkGfm]}>
										{agbText}
									</ReactMarkdown>
                                </div>
                                {showAcceptButton === true ? 
                                    <div className="accept-agb-wrapper">
                                        <button className="button-blue" onClick={() => {setAcceptedAGB(true); setShowAGB(false); setShowAcceptButton(false)}}>AGB akzeptieren</button>
                                    </div> 
                                    :
                                    ""
                                }
                            </>
                        }
                    />
        </>
    );
};

export default AgbModal;