import React, {useMemo, useState} from "react";

import Modal          from "../../components/shared/Modal";
import PackagingTypes from "../../models/PackagingTypes";
import useStore       from "../../Store";
import ParcelOverview from "./ParcelOverview";

const OrderSummary = () => {
	const parcel      = useStore(store => store.parcel)
	const showDetails = useStore(store => store.showParcelDetails)

	const [showSummary, setShowSummary] = useState(false);

	const contents = useMemo(() => {
		if (!parcel)
			return null;
		const content = [];
		const types   = {};

		for (let i = 0; i < 4; i++) {
			const type = PackagingTypes[parcel[`verpackung${i}`]]?.description;

			if (parseInt(parcel[`anzahl${i}`]) < 1 || !type)
				continue;
			if (!types.hasOwnProperty(type))
				types[type] = 0;
			types[type]++;

			const number = types[type];
			const marker = parcel[`markierung${i}`] !== "" ? parcel[`markierung${i}`] : null;
			const amount = parseInt(parcel[`anzahl${i}`]);
			const item   = parcel[`inhalt${i}`] !== "" ? parcel[`inhalt${i}`] : null;
			const weight = parcel[`gewicht${i}`];
			const volume = parcel[`cbm${i}`];
			const length = parcel[`laenge${i}`];
			const width  = parcel[`breite${i}`];
			const height = parcel[`hoehe${i}`];


			content.push({
				type,
				number,
				marker,
				amount,
				item,
				weight,
				volume,
				length,
				width,
				height
			});			
		}

		return content;
	}, [parcel]);

	return (
		<>
			{showDetails ? 
				<section className="order-summary">
					<b className="heading">Lieferung</b>
					<ParcelOverview parcel={parcel} />
					<button className="view-order-details white-button" onClick={() => setShowSummary(true)}>Details zur Lieferung</button>
				</section>	
				: null
			}
			{showDetails ?
				<Modal show={showSummary} setShow={setShowSummary} className="modal-parcel-details">
					<h2 className="notice">Details zur Lieferung</h2>
					<section>
						<ParcelOverview parcel={parcel} />
					</section>
					<section className="grid three-thirds">
						{contents && contents.length > 0 ? 
							contents.map((content, index) => {
								return (
									<article key={index}>
										<h3>{content.type} {content.number}</h3>
										<table>
											<tbody>
												{content.marker ?
												<tr>
													<td>Markierung</td>
													<td>{content.marker}</td>
												</tr>
												: null}
												<tr>
													<td>Anzahl</td>
													<td>{content.amount}</td>
												</tr>
												{content.item ?
													<tr>
														<td>Inhalt</td>
														<td>{content.item}</td>
													</tr>	
												: null}
												{content.weight ? 
													<tr>
														<td>Gewicht</td>
														<td>{content.weight} kg</td>
													</tr>
												: null}
												{content.volume ? 
													<tr>
														<td>Volumen</td>
														<td>{new Intl.NumberFormat('de-DE').format(parseFloat(content.volume))} m&sup3;</td>
													</tr>
												: null}
												{content.length && content.width && content.height?
													<tr>
														<td>Maße LxBxH</td>
														<td>{content.length}x{content.width}x{content.height}</td>
													</tr>
												:null}
											</tbody>
										</table>
									</article>
								)
							})
						: null	
						}
					</section>
				</Modal>
			: null }
		</>
	);
};

export default OrderSummary;