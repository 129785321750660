import React, {useState, useEffect} from "react";

import {Radiobox, Textarea, Checkbox} from "react-inputs-validation";

import useStore from "../Store";

const DropOffLocationOption = ({ onClickAction }) => {
	const {DROP_OPTIONS}                                    = useStore;
	const [selectedDropOffOption, setSelectedDropOffOption] = useStore((store) => [store.selectedDropOffOption, store.setSelectedDropOffOption]);
	const [customMessage, setCustomMessage]                 = useStore((store) => [store.customDropOffMessage, store.setCustomDropOffMessage]);
	const [confirmed, setConfirmed]                         = useState(false);
	const [validate, setValidate]                           = useState(false);

	const [errors, setErrors] = useState({
		location       : true,
		customLocation : true,
		permission     : true
	});

	const dropLocationOptions = [
		{ id: DROP_OPTIONS.GATEWAY, name: "Einfahrt" },
		{ id: DROP_OPTIONS.DOOR,    name: "Haustür" },
		{ id: DROP_OPTIONS.GARAGE,  name: "Garage" },
		{ id: DROP_OPTIONS.CARPORT, name: "Carport" },
		{ id: DROP_OPTIONS.TERRACE, name: "Terrasse" },
		{ id: DROP_OPTIONS.GARDEN,  name: "Garten" },
		{ id: DROP_OPTIONS.CUSTOM,  name: `anderer Ort${selectedDropOffOption === DROP_OPTIONS.CUSTOM ? ":" : ""}` },
	];

	const save = () => {
		setValidate(true);
	};

	useEffect(() => {
		if (!validate)
			return;

		/*
			this is kind of a special case, because we do not care about the error state of
			`customLocation` when its radio button is not selected. therefore we delete the
			option alltogether before consolidating the validity array.
		*/
		const localErrors = {...errors};
		if (selectedDropOffOption !== DROP_OPTIONS.CUSTOM)
			delete localErrors.customLocation;

		/*
			here we consolidate all validity metrics into a single value
			`Object.values()` returns an array in form `[true, false, false]` which we then `Array.reduce()`.
			if all errors are `false`, `valid` becomes `true`.
		*/
		//const valid = Object.values(localErrors).filter(val => val === true).length() < 1;
		const valid = Object.values(localErrors).reduce((accumulator, current) => accumulator && !current, true);
		console.log(localErrors, "sum:", valid);
		if (valid)
			onClickAction();

		setValidate(false);
	}, [validate, errors, selectedDropOffOption, onClickAction, DROP_OPTIONS.CUSTOM]);

	return (
		<>
			<section className="drop-off-premission grid three-thirds">
				<div className="wrapper">
					<p>
						<b>
							Achten Sie bitte unbedingt darauf, dass sich der Abstellort
							unmittelbar an der Empfangsadresse befindet und vom Bürgersteig
							aus durchgehend über befestigte Flächen mit glatter Oberfläche
							durch einen Hubwagen erreichbar sowie für den Zusteller frei
							zugänglich sein muss.
						</b>
					</p>
					<div className="drop-off-location-form">

						<div className="choose-location">
							<p>
								<b>Wählen Sie einen Abstellort für Ihre Lieferung:</b>
							</p>
							<Radiobox
								attributesWrapper={{ className: "drop-off-location-radiobox" }}
								value={selectedDropOffOption}
								validationCallback={val => setErrors(errors => ({...errors, location : val}))}
								validate={validate}
								optionList={dropLocationOptions}
								onChange={(selectedDropOffOption) => {
									setSelectedDropOffOption(selectedDropOffOption);
								}}
								validationOption={{
									name       : "DropOffLocationRadiobox",
									check      : true,
									validate   : true,
									msgOnError : "Bitte wählen Sie einen Abstellort"
							}} />
							{
								selectedDropOffOption === DROP_OPTIONS.CUSTOM ?
								<Textarea
									attributesInput={{
										name      : "different-location",
										rows      : "5",
										maxLength : 100
									}}
									value={customMessage}
									validate={validate && selectedDropOffOption === DROP_OPTIONS.CUSTOM}
									validationCallback={val => setErrors(errors => ({...errors, customLocation : val}))}
									onChange={(customMessage) => {
										setCustomMessage(customMessage);
									}}
									onBlur={() => setErrors(err => err)}
									validationOption={{
										name       : "CustomDropOffMessage",
										msgOnError : "Bitte beschreiben Sie den Abstellort",
								}} />
								: null
							}
							<div className="set-drop-off-permission checkbox-container">
								<Checkbox
									attributesInput={{
										id   : "drop-off-permission",
										name : "drop-off-permission",
									}}
									value={confirmed}
									validate={validate}
									validationCallback={val => setErrors(errors => ({...errors, permission : val}))}
									onChange={(confirmed) => {
										setConfirmed(confirmed);
									}}
									validationOption={{
										name       : "DropOffPermission",
										check      : true,
										required   : true,
										msgOnError : "Bitte bestätigen Sie die Abstellgenehmigung",
									}}
									classNameInputBox="checkbox"
									classNameWrapper="checkbox-wrapper" />
								<label htmlFor="drop-off-permission">
									Hiermit bestätige ich, dass die Lieferung an dem angegebenen
									Ort abgestellt werden darf.
								</label>
							</div>
							<button className="blue-button" onClick={() => save()}>
								Abstellgenehmigung erteilen
							</button>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default DropOffLocationOption;
