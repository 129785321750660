import React from "react";

const ParcelNotFound = () => {
    return (
        <>
            <section className="screen main parcel-not-found">
                <section className="wrapper">
                    <h1 className="notice">
                        KEINE LIEFERUNG GEFUNDEN
                    </h1>
                </section>
                <section className="section-yellow-bg">
                    <section className="wrapper">
                        <p><b>Zu Ihrer Anfrage konnte keine passende Lieferung gefunden werden.</b></p>
                    </section>
                </section>
            </section>
        </>
    );
};

export default ParcelNotFound;