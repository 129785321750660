import React from "react";

import {DateTime} from "luxon";

import useStore from "../Store";

const ParcelLocked = () => {
	const [zipError, setZIPError] = useStore(store => [store.zipError, store.setZIPError]);

    let lockReason = {
        maxTrys: <>Die Bearbeitung Ihrer Lieferung ist wegen zu vieler Fehlversuchen eine Stunde lang gesperrt.</>,
        lockmarkerSet: <>Die angeforderte Lieferung kann nicht bearbeitet werden.</>
    }

    return (
        <>
            <section className="screen main parcel-not-found">
                <section className="wrapper">
                    <h1 className="notice">
                        BEARBEITUNG GESPERRT
                    </h1>
                </section>
                <section className="section-yellow-bg">
                    <section className="wrapper">
                        <p><b>{lockReason.lockmarkerSet}</b></p>
						{zipError !== null ?
							<>
								<p>
									Die Postleitzahl wurde zu oft falsch eingegeben. Versuchen Sie es {DateTime.fromISO(zipError.lockedUntil).toRelative()} erneut.
								</p>	
								<p>
									<button onClick={() => {
										setZIPError(null);
										window.history.back();
									}}>Erneut versuchen</button>
								</p>
							</>
						: null}
                    </section>
                </section>
            </section>
        </>
    );
};

export default ParcelLocked;