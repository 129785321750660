import React      from "react";
import useStore   from "../Store";
import useText    from "./hooks/useText";

const OptionConfirmationBox = ({parcel}) => {
    const choosenOption                                         = useStore(store => store.choosenDeliveryOption);
    const DELIVERY_OPTIONS                                      = Object.freeze(useStore.DELIVERY_OPTIONS);
    const [selectedDropOffOption]                               = useStore(store => [store.selectedDropOffOption, store.setSelectedDropOffOption]);
	const [customMessage]                                       = useStore(store => [store.customDropOffMessage, store.setCustomDropOffMessage]);
    const selfPickupContact                                     = useStore(store => store.selfPickupContact);
    const [declineWrongAddress, declineDelayed, declineMessage] = useStore(store => [store.declineWrongAddress, store.declineDelayed, store.customDeclineMessage]);
    const selectedDeliveryDate                                  = useStore(store => store.selectedDeliveryDate);
	const branchAddress                                         = useStore(store => store.branchAddress);

    const icon = {}
    icon[DELIVERY_OPTIONS.DELIVERY]      =  "avisicon-delivery-date";
    icon[DELIVERY_OPTIONS.DROP]          =  "avisicon-pin";
    icon[DELIVERY_OPTIONS.SELFPICKUP]    =  "avisicon-self-pickup";
    icon[DELIVERY_OPTIONS.DECLINE]       =  "avisicon-refuse-delivery";

    const textDropOffFeedback       = useText("abstellung-feedback", parcel?.firma);
    const textPickupFeedback        = useText("abholung-feedback", parcel?.firma);
    const textDeclineFeedback       = useText("verweigern-feedback", parcel?.firma);

    const message = {}
    message[DELIVERY_OPTIONS.DELIVERY]      = <>
                                                <p>
                                                    <strong>Ihr gewählter Liefertermin:</strong> <br />
                                                    {new Date(selectedDeliveryDate?.date).toLocaleDateString("de-DE", {weekday: "long", day: "numeric", month: "2-digit", year: "numeric"})}<br />
													{selectedDeliveryDate?.text}
                                                </p>
                                            </>
    message[DELIVERY_OPTIONS.DROP]          = <>
                                                <p>
                                                    <strong>Sie haben eine Abstellgenehmigung erteilt:</strong> <br />
                                                    {selectedDropOffOption} <br /><br />
                                                    {customMessage ? "Sie haben folgende Angabe zum Ablageort hinterlegt:" : ""}
                                                    <br />
                                                    {customMessage ? customMessage : ""}
                                                </p>
                                                <p>
                                                    {textDropOffFeedback}
                                                </p>
                                            </>
    message[DELIVERY_OPTIONS.SELFPICKUP]    = <>
                                                <p>
                                                    <strong>{textPickupFeedback}</strong>
                                                </p>
                                                <p>
                                                    <strong>Abholadresse:</strong> <br />
													{branchAddress?.nl_name}<br />
													{branchAddress?.straße}<br />
													{branchAddress?.plz} {branchAddress?.ort}
                                                </p>
                                                <p>
                                                    <strong>Abholende Person:</strong> <br />
                                                    {selfPickupContact}
                                                </p>
                                               </>
    message[DELIVERY_OPTIONS.DECLINE]       = <>
                                                <p>
                                                    <strong>{textDeclineFeedback}</strong>
                                                </p>
                                                <p>
                                                    <strong>Grund:</strong> <br />
                                                    { declineWrongAddress ? <>Addresse fehlerhaft <br /> </> : <></>}
                                                    { declineDelayed ? <>Lieferung zu spät <br /></> : <></>}

                                                    { declineMessage ? <>{declineMessage} <br />  </> : <></>}
                                                    
                                                </p>
                                              </>

    return (
        <>
            <div className="option-confirmation-box">
                <i className={icon[choosenOption]} />
                <div>
                    {message[choosenOption]}
                </div>
            </div>
        </>
    );
};

export default OptionConfirmationBox;