import create from 'zustand'

const useStore = create(
    (set, get) => ({

        // Data
        parcel                  : null,
        parcelMailContact       : "",
        parcelSMSContact        : "",
        parcelContactPerson     : "",
        zip                     : "",
        deliveryDates           : [],
        deliveryConfirmation    : false,
        showParcelDetails       : false,
        choosenDeliveryOption   : null,
        selectedDropOffOption   : null,
        customDropOffMessage    : "",
        declineWrongAddress     : false,
        declineDelayed          : false,
        customDeclineMessage    : "",
        selfPickupContact       : "",
        selfPickupAddress       : <>ROBERT MÜLLER <br /> Ostring 57 <br /> 66740 Saarlouis</>,
        selectedDeliveryDate    : null,
        showAGB                 : false,
        acceptedAGB             : false,
        showAcceptButton        : false,
		texts                   : null,
		zipError                : null,
		deliveryDateFound       : null,
		processFinished         : false,
		readOnly                : false,
		completedInfo           : null,
		branchAddress           : null,

		cleanZip                : () => {
			const {zip} = get();

			return zip.trim().replace(/\s/g,"");
		},

        // Setter
        setParcel                   : (parcel)                  =>  {set((state) => {
                                                                        let mailContact = ""
                                                                        let smsContact = ""
                                                                        let contactPerson = parcel?.empfangsperson ?? ""

                                                                        if(parcel?.kontakt_art2 === "Mail")
                                                                            mailContact = parcel?.kontakt2
                                                                        if(parcel?.kontakt_art1 === "Mail")
                                                                            mailContact = parcel?.kontakt1
                                                                        if(parcel?.kontakt_art2 === "SMS")
                                                                            smsContact = parcel?.kontakt2.replace("0049", "+49")
                                                                        if(parcel?.kontakt_art1 === "SMS")
                                                                            smsContact = parcel?.kontakt1.replace("0049", "+49")
                                                                        
                                                                        return {parcel, 
                                                                                parcelMailContact: mailContact, 
                                                                                parcelSMSContact: smsContact,
                                                                                parcelContactPerson: contactPerson
                                                                                };
                                                                    });},
        setZip                      : (zip)                     => {set((state) => ({zip}))},
        setShowAGB                  : (showAGB)                 => {set((state) => ({showAGB}))},
        setAcceptedAGB              : (acceptedAGB)             => {set((state) => ({acceptedAGB}))},
        setShowAcceptButton         : (showAcceptButton)        => {set((state) => ({showAcceptButton}))},
        setDeliveryDates            : (deliveryDates)           => {set((state) => ({deliveryDates}))},
        setDeliveryConfirmation     : (deliveryConfirmation)    => {set((state) => ({deliveryConfirmation}))},
        setShowParcelDetails        : (showParcelDetails)       => {set((state) => ({showParcelDetails}))},
        setChoosenDeliveryOption    : (choosenDeliveryOption)   => {set((state) => ({choosenDeliveryOption}))},
        setSelectedDropOffOption    : (selectedDropOffOption)   => {set((state) => ({selectedDropOffOption}))},
        setCustomDropOffMessage     : (customDropOffMessage)    => {set((state) => ({customDropOffMessage}))},
        setSelfPickupContact        : (selfPickupContact)       => {set((state) => ({selfPickupContact}))},
        setSelfPickupAddress        : (selfPickupAddress)       => {set((state) => ({selfPickupAddress}))},
        setDeclineWrongAddress      : (declineWrongAddress)     => {set((state) => ({declineWrongAddress}))},
        setDeclineDelayed           : (declineDelayed)          => {set((state) => ({declineDelayed}))},
        setCustomDeclineMessage     : (customDeclineMessage)    => {set((state) => ({customDeclineMessage}))},
        setSelectedDeliveryDate     : (selectedDeliveryDate)    => {set((state) => ({selectedDeliveryDate}))},
        setParcelMailContact        : (parcelMailContact)       => {set((state) => ({parcelMailContact}))},
        setParcelSMSContact         : (parcelSMSContact)        => {set((state) => ({parcelSMSContact}))},
        setParcelContactPerson      : (parcelContactPerson)     => {set((state) => ({parcelContactPerson}))},
        setTexts                    : texts                     => {set(state => ({texts}))},
        setZIPError                 : zipError                  => {set(state => ({zipError}))},
        setDeliveryDateFound        : deliveryDateFound         => {set(state => ({deliveryDateFound}))},
        setProcessFinished          : processFinished           => {set(state => ({processFinished}))},
        setReadOnly                 : readOnly                  => {set(state => ({readOnly}))},
        setCompletedInfo            : completedInfo             => {set(state => ({completedInfo}))},
        setBranchAddress            : branchAddress             => {set(state => ({branchAddress}))}
}));

useStore.DELIVERY_OPTIONS = Object.freeze({
    DELIVERY   : 1,
    DROP       : 2,
    SELFPICKUP : 3,
    DECLINE    : 4
})

useStore.DROP_OPTIONS = Object.freeze({
    GATEWAY     : "Einfahrt",
    DOOR        : "Haustür",
    GARAGE      : "Garage",
    CARPORT     : "Carport",
    TERRACE     : "Terrasse",
    GARDEN      : "Garten",
    CUSTOM      : undefined
})


export default useStore