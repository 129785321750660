import React, {useEffect} from "react";

import useStore from "../../Store";

import ConfirmationMessage   from "../../components/ConfirmationMessage";

const CompletedParcelInfo = () => {
	const store            = useStore(store => store);
	const DeliveryOption   = useStore.DELIVERY_OPTIONS;

	const {
		completedInfo,

		setChoosenDeliveryOption,
		setSelectedDeliveryDate,
		setSelfPickupContact
	} = store;

	useEffect(() => {
		if (!completedInfo)
			return;
		if (completedInfo.selbstabholer) {
			setChoosenDeliveryOption(DeliveryOption.SELFPICKUP);
			setSelfPickupContact(completedInfo.vereinbart_mit);
		} else if (completedInfo.abstell) {
			setChoosenDeliveryOption(DeliveryOption.DROP);
		} else if (completedInfo.av) {
			setChoosenDeliveryOption(DeliveryOption.DECLINE);
		} else {
			setChoosenDeliveryOption(DeliveryOption.DELIVERY);
			setSelectedDeliveryDate({
				date : completedInfo.termin
			});
		}
	}, [DeliveryOption, completedInfo, setChoosenDeliveryOption, setSelectedDeliveryDate, setSelfPickupContact]);

	return (
		<>
			<ConfirmationMessage />
		</>
	);
};

export default CompletedParcelInfo;