import React from "react";

import ReactMarkdown from "react-markdown";
import remarkGfm     from "remark-gfm";

import useStore from "../Store";
import useText  from "./hooks/useText";

const Footer = () => {
	const parcel     = useStore(store => store.parcel);
	const setShowAGB = useStore((store) => store.setShowAGB);
	const footerText = useText("footer", parcel?.firma);

	return (
		<footer>
			<div className="wrapper">
				<ReactMarkdown remarkPlugins={[remarkGfm]}>
					{footerText}
				</ReactMarkdown>
				<nav className="footer">
					<a href="https://www.robert-mueller.com/kontakt/impressum/" target="_blank" rel="noreferrer">Impressum</a>
					<a href="/" onClick={(event) => {event.preventDefault(); setShowAGB(true)}}>AGB</a>
					<a href="https://www.robert-mueller.com/service/allgemeine-geschaeftsbedingungen" target="_blank" rel="noreferrer">Datenschutz</a>
				</nav>
			</div>
		</footer>
	);
};

export default Footer;