import React    from "react";
import useStore from "../Store";
import useText from "./hooks/useText";

const DeliveryDateOption = ({deliveryDates, onClickAction, parcel}) => {
	const textNoAppointment       = useText("zustellung-keintermin", parcel?.firma);
	const setSelectedDeliveryDate = useStore(store => store.setSelectedDeliveryDate)

    return (
        <>
            <section className="alternative-delivery-dates">
				<p><b>Bitte wählen Sie einen der folgenden Termine aus:</b></p>
				{deliveryDates.length > 0 ?
					deliveryDates.map((date, index) => {
						if (index === 0)
							return null;
						return (
							<div className="date-suggestion grid three-thirds" key={index}>
								<div>
									<p><i className="avisicon-delivery-date"></i><b>Terminvorschlag {index}</b></p>
								</div>
								<div className="date">
									<p>
										{new Date(date.date).toLocaleDateString("de-DE", {weekday: "long", day: "numeric", month: "2-digit", year: "numeric"})}<br />
                                        {date.text}
									</p>
								</div>
								<button className="blue-button choose-first-suggestion" onClick={(event) => {event.preventDefault(); setSelectedDeliveryDate(date); onClickAction()}}>Diesen Termin bestätigen</button>
							</div>
						);
					})
				: null}
				<p>
					<b>{textNoAppointment}</b>
				</p>
			</section>
        </>
    );
};

export default DeliveryDateOption;