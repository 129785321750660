import {useEffect, useState} from "react";
import axios from "axios";

import {useAlert} from "react-alert";

import useStore from "../../Store";

let IsLoading = false;

const useText = (key, firma = "SLS") => {
	const alert             = useAlert();
	const [value, setValue] = useState(null);
	const [texts, setTexts] = useStore(store => [store.texts, store.setTexts]);

	useEffect(() => {
		if (texts !== null || IsLoading)
			return;

		IsLoading = true;

		console.log("loading texts...");
		axios.get("/texts").then(response => {
			if (response.data.code !== 200) {
				alert.error(`Fehler beim Laden der Applikationstexte (${response.data?.code}).`);
				return;
			}
			const newTexts = {SLS : {}};
			for (const textObject of response.data.data) {
				const {firma, text_feld, text_wert} = textObject;
				if (!newTexts.hasOwnProperty(firma))
					newTexts[firma] = {};
				newTexts[firma][text_feld] = text_wert;
			}
			setTexts(newTexts);
			console.log("DONE! loading texts");
		}).catch(error => alert.error(`Fehler beim Laden der Applikationstexte: ${error}`));
	}, [texts, setTexts, alert]);

	useEffect(() => {
		if (!texts)
			return;
		const fa = texts.hasOwnProperty(firma) ? firma : "SLS";

		setValue(texts[fa].hasOwnProperty(key) ? texts[firma][key] : (texts["SLS"].hasOwnProperty(key) ? texts["SLS"][key] : null));
	}, [texts, key, firma]);

	return value;
};

export default useText;