import React    from "react";
import ReactDOM from "react-dom";

import AlertTemplate from "./components/AlertTemplate";
import WebAVIS       from "./components/WebAVIS";

import {transitions, positions, Provider as AlertProvider} from "react-alert";

import "./css/web-avis-frontend.scss";

const alertOptions = {
	//timeout     : 5000,
	positions   : positions.TOP_CENTER,
	transitions : transitions.SCALE
};

ReactDOM.render(
  <React.StrictMode>
	  <AlertProvider template={AlertTemplate} {...alertOptions}>
    		<WebAVIS />
	  </AlertProvider>
  </React.StrictMode>,
  document.getElementById("root")
);