import {useEffect} from "react";
import axios from "axios";

import {useAlert} from "react-alert";

import assert   from "../util/assert";
import useStore from "../Store";

const Finisher = () => {
	const alert = useAlert();
	const state = useStore();
	const DeliveryOption = useStore.DELIVERY_OPTIONS;
	
	const {
		deliveryConfirmation,
		setProcessFinished,
		choosenDeliveryOption,

		cleanZip,
		parcel,
		selectedDeliveryDate,
		parcelContactPerson,

		selectedDropOffOption,
		customDropOffMessage,

		declineDelayed,
		declineWrongAddress,
		customDeclineMessage
	} = state;

	useEffect(() => {
		if (!deliveryConfirmation)
			return;

		switch (choosenDeliveryOption) {
			case DeliveryOption.DELIVERY:
				axios.post("/confirm/appointment", {
					parcelId             : parcel.avis_id,
					selectedDeliveryDate : selectedDeliveryDate.date,
					zip                  : cleanZip(),
					parcelContactPerson
				}).then(response => {
					const {code, message} = response.data;

					if (code === 200)
						setProcessFinished(true);
					else
						alert.error(`Fehler beim Bestätigen der Lieferdaten: ${message}`);
				}).catch(error => alert.error(`Fehler bei der Terminbestätigung: ${error}`));
				break;
			case DeliveryOption.DROP:
				const location = selectedDropOffOption ? selectedDropOffOption : customDropOffMessage;
				axios.post("/confirm/drop-off", {
					parcelId : parcel.avis_id,
					zip      : cleanZip(),
					location,
					parcelContactPerson
				}).then(response => {
					const {code, message} = response.data;

					if (code === 200)
						setProcessFinished(true);
					else
						alert.error(`Fehler beim Bestätigen der Lieferdaten: ${message}`);
				}).catch(error => alert.error(`Fehler bei der Terminbestätigung: ${error}`));
				break;
			case DeliveryOption.SELFPICKUP:
				axios.post("/confirm/self-pickup", {
					parcelId : parcel.avis_id,
					zip      : cleanZip(),
					parcelContactPerson
				}).then(response => {
					const {code, message} = response.data;

					if (code === 200)
						setProcessFinished(true);
					else
						alert.error(`Fehler beim Bestätigen der Lieferdaten: ${message}`);
				}).catch(error => alert.error(`Fehler bei der Terminbestätigung: ${error}`));
				break;
			case DeliveryOption.DECLINE:
				let reason;

				if (declineWrongAddress)
					reason = "Adresse fehlerhaft";
				else if (declineDelayed)
					reason = "Lieferung zu spät";
				else if (customDeclineMessage !== "")
					reason = customDeclineMessage;
				else
					assert(false, "unreachable, decline reason should be set.");

				axios.post("/confirm/decline", {
					parcelId : parcel.avis_id,
					zip      : cleanZip(),
					reason,
					parcelContactPerson
				}).then(response => {
					const {code, message} = response.data;

					if (code === 200)
						setProcessFinished(true);
					else
						alert.error(`Fehler beim Bestätigen der Lieferdaten: ${message}`);
				}).catch(error => alert.error(`Fehler bei der Terminbestätigung: ${error}`));
				break;
			default:
				assert(false, `chosenDeliveryOption is '${choosenDeliveryOption}' this code is unreachable`);
		}
		console.log("FERTIG!");
		//setProcessFinished(true);
	}, [deliveryConfirmation, setProcessFinished, choosenDeliveryOption, DeliveryOption, alert, cleanZip, customDeclineMessage, customDropOffMessage, declineDelayed, parcel, selectedDeliveryDate, parcelContactPerson, selectedDropOffOption, declineWrongAddress]);

	return null;
};

export default Finisher;