import React, {useEffect, useCallback} from "react";
import axios from "axios";

import {useParams}   from "react-router";
import {useNavigate} from "react-router-dom"
import {useAlert}    from "react-alert";

import ReceiverAddress from "./ReceiverAddress";
import SenderAddress   from "./SenderAddress";
import OrderSummary    from "./OrderSummary";
import ModifyParcel    from "./ModifyParcel";
import useStore        from "../../Store"
import APIError        from "../../models/APIError";

const ModifyOrder = () => {
	const alert                         = useAlert();
	const redirectTo                    = useNavigate();
	const {key}                         = useParams();
	const [showDetails, setShowDetails] = useStore(store => [store.showParcelDetails, store.setShowParcelDetails]);
	const [setZip, cleanZIP]            = useStore(store => [store.setZip, store.cleanZip]);
	const [parcel, setParcel]           = useStore(store => [store.parcel, store.setParcel]);
	const setDeliveryDates              = useStore(store => store.setDeliveryDates);
	const deliveryConfirmation          = useStore(store => store.deliveryConfirmation);
	const [zipError, setZIPError]       = useStore(store => [store.zipError, store.setZIPError]);
	const setDeliveryDateFound          = useStore(store => store.setDeliveryDateFound);
	const [readOnly, setReadOnly]       = useStore(store => [store.readOnly, store.setReadOnly]);
	const setCompletedInfo              = useStore(store => store.setCompletedInfo);
	const setBranchAddress              = useStore(store => store.setBranchAddress);

	useEffect(() => {
		axios.get(`/parcels/${key}`).then(response => {
			const {data} = response;
			if (data.code === 200) {
				setParcel(response.data.data);

				return;
			} else if (data.code === APIError.TOO_MANY_WRONG_ZIPS) {
				setZIPError(data.data);
				redirectTo("/parcel-locked");
			} else if (data.code === APIError.ALREADY_PRESENT) {
				// @ToDo new screen for already finished parcels
				//redirectTo("/parcel-not-found");
				setReadOnly(true);	
				setParcel(response.data.data);
			} else
				redirectTo("/parcel-not-found");
		}).catch(error => {
			console.log(error);
			redirectTo("/");
		});
	}, [key, redirectTo, alert, setParcel, setZIPError, setReadOnly]);

	const loadDetails = useCallback(() => {
		axios.post(`/parcels/${key}/details`, {zip : cleanZIP()}).then(response => {
			if (response.data.code === 200) {
				setParcel(response.data.data);
				setShowDetails(true);

				// get info about if parcel is already completed
				axios.post(`/parcels/${response.data.data.avis_id}/completed`, {zip : cleanZIP()}).then(response => {
					setCompletedInfo(response.data.data);
				}).catch(error => alert.error(`Fehler beim Laden der Liefermodalitäten: ${error}`));

				// get possible appointments
				axios.post(`/appointments/${key}`, {zip : cleanZIP()}).then(response => {
					if (response.data.code === 200) {
						setDeliveryDates(response.data.data);
					} else if (response.data.code === APIError.INFINITE_LOOP) {
						setDeliveryDateFound(false);
					} else {
						setDeliveryDateFound(false);
						console.error(response);
					}
				}).catch(error => console.log(error));

				// get branch address for `parcel.firma`
				axios.get(`/address/${response.data.data.firma}`).then(response => {
					setBranchAddress(response.data.data);
				}).catch(error => alert.error(`Fehler beim Laden der Addressdaten der Niederlassung: ${error}`));
			} else if (response.data.code === APIError.WRONG_ZIP) {
				setZIPError(response.data.data);
				if (response.data.data.attemptsRemaining < 1)
					redirectTo("/parcel-locked");
			}
		}).catch(error => {
			console.log(error);
			redirectTo("/");
		});
	}, [cleanZIP, key, redirectTo, setShowDetails, setParcel, setDeliveryDates, setZIPError, setDeliveryDateFound, alert, setBranchAddress, setCompletedInfo]);

	const validateForm = useCallback(event => {
		event.preventDefault();
		loadDetails();
    }, [loadDetails]);

	if (!parcel)
		return <></>

	return (
		<section className="screen main modify-order">
			<section className="wrapper">
				<h2 className="notice">
					<img src="/images/icon_delivery.svg" alt="Lieferung" /><br />
					{!deliveryConfirmation ? (readOnly ? "Ihre Lieferung wurde bereits bestätigt." : "Wir haben eine Lieferung für Sie.") : "Vielen Dank für die Bestätigung."}
				</h2>
				{(readOnly && !showDetails) && (
					<p>
						Ihre Lieferkonditionen wurden bereits bestätigt. Um sich die Details der Lieferung erneut anzusehen, geben Sie bitte erneut die Postleitzahl ein.
					</p>
				)}
				<section className="order-details grid three-thirds">
					<ReceiverAddress title="Empfänger" />
					<SenderAddress title="Absender" />
					<OrderSummary />
				</section>
				{!showDetails ? 
					<p>
						<b>Um den Liefertermin und die Lieferdetails festzulegen, benötigen wir zuerst die Bestätigung der Postleitzahl des Empfangsortes.</b>	
					</p>
					: null
				}
			</section>
			{showDetails ? 
				<ModifyParcel parcel={parcel} />
					:
				<>
					<section className="zip-input">
						<section className="wrapper">
							<form onSubmit={validateForm}>

								<h2>Bitte bestätigen Sie die Postleitzahl des Empfangsortes:</h2>
								<input type="text" name="Postal-Code" value={cleanZIP()} onChange={event => setZip(event.target.value)} placeholder="Postleitzahl" pattern="[a-zA-Z]?[0-9]{4,5}" autoComplete="off" />
								{zipError ?
									<p className="error">
										PLZ falsch, noch {zipError.attemptsRemaining} Versuche
									</p>
								: null}
								<p>
									<button>Weiter <span className="avisicon avisicon-arrow-right"></span></button>
								</p>
							</form>
						</section>
					</section>
				</>	
			}
		</section>
	);
};

export default ModifyOrder;
