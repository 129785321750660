import React, {useState, useEffect}    from 'react'
import useStore             from '../Store'
import {Radiobox, Textarea} from "react-inputs-validation"

const DeclineOption = ({onClickAction}) => {
    const setWrongAddress                   = useStore(store => store.setDeclineWrongAddress)
    const setDelayed                        = useStore(store => store.setDeclineDelayed)
    const [description, setDescription]     = useStore(store => [store.customDeclineMessage, store.setCustomDeclineMessage])
    
    const [declineReason, setDeclineReason]             = useState("");
    const [validate, setValidate]                       = useState(false);
    
    const [errors, setErrors] = useState({
        reason:         true,
        customMessage:  true
    });

    const declineReasons = [
        { id: "address", name: "Adresse fehlerhaft" },
        { id: "delayed", name: "Lieferung zu spät" },
        { id: "custom", name: "sonstiger Grund:" }
    ]

    const save = () => {
        setValidate(true);
    };

    useEffect(() => {
        if (!validate)
            return;

        const localErrors = {...errors};
        if (declineReason !== "custom")
            delete localErrors.customMessage;

        const vaild = Object.values(localErrors).reduce((accumulator, current) => accumulator && !current, true);

        if (vaild)
            onClickAction();
        
        setValidate(false);
      
    }, [validate, errors, declineReason, onClickAction]);

    return (
        <section className='col-one-right'>
                    <div>
                            <b>Bitte geben Sie den Grund an:</b>
                            <Radiobox 
                                attributesWrapper={{className: "decline-reasons-radiobox"}}
                                value={declineReason}
                                validate={validate}
                                validationCallback={val => setErrors(errors => ({...errors, reason: val}))}
                                optionList={declineReasons}
                                onChange={(declineReason) => {
                                    setDeclineReason(declineReason);
                                    declineReason === "address" ? setWrongAddress(true) : setWrongAddress(false);
                                    declineReason === "delayed" ? setDelayed(true) :  setDelayed(false);
                                    
                                }}
                                validationOption={{
                                    name: "DeclineReasonRadiobox",
                                    check: true,
                                    validate: true,
                                    msgOnError: "Bitte wählen Sie einen Grund aus"
                                }}

                            />
                            {
                                declineReason === "custom" ? 
                                    <Textarea 
                                        value={description}
                                        validate={validate && declineReason === "custom"}
                                        validationCallback={val => setErrors(errors => ({...errors, customMessage: val}))}
                                        onChange={(description) => {
                                            setDescription(description);
                                        }}
                                        onBlur={() => setErrors(err => err)}
                                        validationOption={{
                                            name: "CustomMessage",
                                            msgOnError: "Geben Sie einen Grund an"
                                        }}
                                    />

                                : null
                            }
                            <br />
                            <button className='blue-button' onClick={() => save()}> Annahme verweigern </button>
                    </div>
        </section>
    )
}


export default DeclineOption
