const PackagingTypes = Object.freeze({
	EW : {
		description : "Einwegpalette",
		association : "Palette"
	},
	EU : {
		description : "Europalette",
		association : "Palette"
	},
	GB : {
		description : "Gitterbox",
		association : "Palette"
	},
	DD : {
		description : "Düsseldorfer",
		association : "Palette"
	},
	H1 : {
		description : "Kunststoff-Palette",
		association : "Palette"
	},
	KN : {
		description : "Kanister",
		association : "Behälter"
	},
	BE : {
		description : "Beutel",
		association : "Behälter"
	},
	EI : {
		description : "Eimer",
		association : "Behälter"
	},
	KA : {
		description : "Kanne",
		association : "Behälter"
	},
	FL : {
		description : "Flasche",
		association : "Behälter"
	},
	KF : {
		description : "Korbflasche",
		association : "Behälter"
	},
	KO : {
		description : "Korbflasche",
		association : "Behälter"
	},
	DO : {
		description : "Dose",
		association : "Behälter"
	},
	TRY : {
		description : "Tray",
		association : "Behälter"
	},
	SA : {
		description : "Sack",
		association : "Behälter"
	},
	EB : {
		description : "Einweg-Behälter",
		association : "Behälter"
	},
	MTV : {
		description : "MTV Behälter",
		association : "Behälter"
	},
	KB : {
		description : "Kundeneig. Sonderbehälter",
		association : "Behälter"
	},
	SB : {
		description : "Spedit. Behälter",
		association : "Behälter"
	},
	RE : {
		description : "Reifen",
		association : "Colli"
	},
	RO : {
		description : "Rolle",
		association : "Colli"
	},
	CC : {
		description : "Collico",
		association : "Colli"
	},
	CX : {
		description : "MTV Collico",
		association : "Colli"
	},
	HS : {
		description : "Haspel",
		association : "Colli"
	},
	RG : {
		description : "Ring",
		association : "Colli"
	},
	CL : {
		description : "Colli",
		association : "Colli"
	},
	ST : {
		description : "Stück",
		association : "Colli"
	},
	UV : {
		description : "Unverpackt",
		association : "Colli"
	},
	CO : {
		description : "Colli",
		association : "Colli"
	},
	TR : {
		description : "Trommel",
		association : "Colli"
	},
	IBC : {
		description : "Inter Bulk Container",
		association : "Container"
	},
	TC : {
		description : "Tankcontainer",
		association : "Container"
	},
	HO : {
		description : "Hobbock",
		association : "Fass"
	},
	FA : {
		description : "Fass",
		association : "Fass"
	},
	DR : {
		description : "Drum",
		association : "Fass"
	},
	BU : {
		description : "Bund",
		association : "Gebinde"
	},
	GE : {
		description : "Gebinde",
		association : "Gebinde"
	},
	BL : {
		description : "Ballen",
		association : "Gebinde"
	},
	RA : {
		description : "Rahmen",
		association : "Gestell"
	},
	GS : {
		description : "Gestell",
		association : "Gestell"
	},
	VG : {
		description : "Verschlag",
		association : "Gestell"
	},
	KT : {
		description : "Karton",
		association : "Karton"
	},
	PA : {
		description : "Paket",
		association : "Karton"
	},
	FK : {
		description : "Faltkiste",
		association : "Kiste"
	},
	EK : {
		description : "E2Kiste",
		association : "Kiste"
	},
	KI : {
		description : "Kiste",
		association : "Kiste"
	},
	PK : {
		description : "Pack",
		association : "Kiste"
	},
	KS : {
		description : "Kasten",
		association : "Kiste"
	},
	VP : {
		description : "Viertelpalette",
		association : "Palette"
	},
	HP : {
		description : "Halbpalette",
		association : "Palette"
	},
	CP : {
		description : "Chep-Palette",
		association : "Palette"
	},
	DP : {
		description : "Düsseldorfer",
		association : "Palette"
	},
	EP : {
		description : "Einweg Palette",
		association : "Palette"
	},
	FP : {
		description : "Euro-Flachpalette",
		association : "Palette"
	},
	KP : {
		description : "Kundeneigene Sonderpalette",
		association : "Palette"
	},
	GP : {
		description : "DB Gitterboxpalette",
		association : "Palette"
	},
	EE : {
		description : "EE mit Euromaß",
		association : "Palette"
	},
	HE : {
		description : "H1 Palette",
		association : "Palette"
	},
	KGP : {
		description : "Kundeneig. Gitterbox",
		association : "Palette"
	},
	KFP : {
		description : "Kundeneig. Euro-Flachpalette ",
		association : "Palette"
	},
	IP : {
		description : "Industriepalette",
		association : "Palette"
	},
	KG : {
		description : "Kiga Kunststoffpalette",
		association : "Palette"
	},
	RW : {
		description : "Rollwagen TC",
		association : "Rollcontainer"
	},
	RC : {
		description : "Rollcontainer",
		association : "Rollcontainer"
	}
});

export default PackagingTypes;
