import React    from "react";
import useStore from "../../Store";

const SenderAddress = ({title}) => {
	const parcel      = useStore(store => store.parcel)
	const showDetails = useStore(store => store.showParcelDetails)

	return (
		<>
			<address>
				<b className="heading">{title}</b>
				{showDetails ?
					<>
						<p>
							{parcel?.ursprung_kunde}<br />
							{parcel?.kunde_strasse}<br />
							{parcel?.kunde_plz} {parcel?.auft_ort}
						</p>
					</> : 
					<>
						<p>
							{parcel?.auftraggeber}
						</p>
					</>
				}
			</address>
		</>
	);
};

export default SenderAddress;