import React from "react";

/*
$avisicon-alert-warn_error: "\e908";
$avisicon-alert-success: "\e909";
$avisicon-alert-info: "\e90a";
*/

const getIconName = type => {
	switch (type) {
		case "error":
		case "warning":
			return "alert-warn_error";
		case "success":
			return "alert-success";
		case "info":
		default:
			return "alert-info";		
	}
};

const AlertTemplate = ({style, options, message, close}) => {
	return (
		<div style={style} className={`alert ${options.type}`}>
			<button className="outline thin plaintext pull-right" onClick={close}><span className="avisicon avisicon-abort"></span></button>
			<section className="grid ten-ninety">
				<span className={`avisicon alert-icon avisicon-${getIconName(options.type)}`}></span>
				<span>
					{message}
				</span>
			</section>
		</div>
	);
};

export default AlertTemplate;