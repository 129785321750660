import React from "react";

const Header = () => {
	return (
		<header>
			<div className="wrapper">
				<section className="logo">
					<img src="/images/logo_robert_mueller_gmbh.svg" alt="Robert Müller GmbH" />
				</section>
			</div>			
		</header>
	);
};

export default Header;